/* Example UMD module.
 * Please remove comments before use.
 */

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("Tooltip", ["jquery", "Behaviors"], factory);
    } else {
        root.Tooltip = factory(root.jQuery, root.Behaviors);
    }
    /* Also make sure to have named arguments in your function in the same
     * order as what you put in your AMD dependencies or globals call.
     */
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    /* Your code goes here.
     *
     * This is known as the factory function. It takes as parameters your
     * dependencies. Make sure that your argument names, the AMD definition,
     * and the non-AMD defintion lines above all agree, or you're gonna have a
     * bad time.
     *
     * If you wish to export code for other modules to use, please return a
     * suitable object or function. This returned value will become a global
     * variable named after your module on non-AMD pages and will be processed
     * by the AMD machinery on AMD pages.
     */
    
    function TooltipActivator(elem) {
        Behaviors.init(TooltipActivator, this, arguments);

        var width = $(window).width();
        if ( (width < 769) || ((width >= 1200 ) && (width < 1599)) ) {
            var triggerType;
            this.$elem.tooltipster({
                side: 'top',
                position: 'top',
                trigger: 'custom',
                animationDuration: 0,
                triggerOpen: {
                    click: true,
                    tap: true,
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    click: true,
                    tap: true,
                    scroll: true,
                    mouseleave: true,
                    originClick: true,
                    touchleave: true
                }
            });
        }

        else {
            this.$elem.tooltipster({
                side: 'top',
                position: 'top',
                trigger: 'custom',
                animationDuration: 0,
                triggerOpen: {
                    click: true,
                    tap: true,
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    click: true,
                    tap: true,
                    scroll: true,
                    mouseleave: true,
                    originClick: true,
                    touchleave: true
                }
            });
        }
    }

    Behaviors.inherit(TooltipActivator, Behaviors.Behavior);

    TooltipActivator.QUERY = "[data-tooltip], .Tooltip";

    Behaviors.register_behavior(TooltipActivator);

    module.TooltipActivator = TooltipActivator;

    return module;
}));
