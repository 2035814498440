(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("cartdrawer", ["jquery", "betteroffcanvas"], factory);
    } else {
        // Browser globals
        root.siteheader = factory(root.jQuery, root.betteroffcanvas);
    }
}(this, function ($, betteroffcanvas) {
    "use strict";


    // Define the PHP function to call from here
    $('.single_add_to_cart_button').on('click',function(e) {
        betteroffcanvas.initOffcanvas($('#CartDrawer'));
		betteroffcanvas.openOffcanvas($('#CartDrawer'));
    });

}));
