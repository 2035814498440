/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("GallerySlider", ["jquery", "Behaviors", "jquery.slick"], factory);
    } else {
        root.HeroHeaderSlider = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    function HeroHeaderSlider(elem) {
        Behaviors.init(HeroHeaderSlider, this, arguments);

        var slide_count = this.$elem.children().length;

        if(slide_count > 1) {
            this.$elem.slick({
                arrows: false,
                asNavFor: HeroHeaderDots.QUERY,
                fade: true,
                centerMode: true,
                lazyLoad: 'ondemand',
            });
        }
    }

    Behaviors.inherit(HeroHeaderSlider, Behaviors.Behavior);
    HeroHeaderSlider.QUERY = "[data-heroheader-slider]";

    function HeroHeaderDots(elem) {
        Behaviors.init(HeroHeaderDots, this, arguments);

        var dot_count = this.$elem.children().length;
        if(dot_count > 1) {
            this.$elem.slick({
                arrows: false,
                slidesToShow: dot_count,
                asNavFor: HeroHeaderSlider.QUERY,
                focusOnSelect: true,
                swipe: false,
            });
        }
    }

    Behaviors.inherit(HeroHeaderDots, Behaviors.Behavior);
    HeroHeaderDots.QUERY = "[data-heroheader-slickdots]";

    Behaviors.register_behavior(HeroHeaderSlider);
    Behaviors.register_behavior(HeroHeaderDots);

    module.HeroHeaderSlider = HeroHeaderSlider;
    module.HeroHeaderDots = HeroHeaderDots;

    return module;
}));
