/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("LoreSlider", ["jquery", "Behaviors", "jquery.slick"], factory);
    } else {
        root.LoreSlider = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    function LoreSlider(elem) {
        Behaviors.init(LoreSlider, this, arguments);

        var slide_count = this.$elem.children().length;
        if(slide_count > 1) {
            this.$elem.slick({
                arrows: true,
                prevArrow: '<button type="button" class="Lore-slider--arrow Lore-slider--prev slick-prev">Previous</button>',
                nextArrow: '<button type="button" class="Lore-slider--arrow Lore-slider--next slick-next">Next</button>',
                fade: true
            });

            var modal = this.$elem.parents('[data-modal]');
            modal.on('offcanvas-open', function() {
                console.log('resized');
                this.$elem.resize();
            }.bind(this));
        }
    }

    Behaviors.inherit(LoreSlider, Behaviors.Behavior);
    LoreSlider.QUERY = "[data-lore-slider]";

    Behaviors.register_behavior(LoreSlider);

    module.LoreSlider = LoreSlider;

    return module;
}));
