(function ($) {
    $(document).bind('ready ajaxStop gform_post_render', function(){
        $('.FormItem').each(function(i, elem) {
            if($(elem).find('input:not([type=radio]):not([type=checkbox])').val() !== "") {
                if($(elem).hasClass('.FormItem-input_wrapper')) {
                    $(this).find('label').addClass('selected');
                }
            }
			$(elem).find('input:not([type=radio]):not([type=checkbox])').on('blur', function(){
	        	$(elem).removeClass('selected');
                $(elem).find('label').removeClass('selected');
                if($(this).val() !== "") {
                    $(elem).find('label').addClass('selected');
                }

      		}).on('focus', function(){
        		$(elem).addClass('selected');
                $(elem).find('label').addClass('selected');
                $(this).addClass('selected');
        	});

			$(elem).find('textarea').on('blur', function(){
				$(elem).removeClass('selected');
                $(elem).find('label').removeClass('selected');
                if($(this).val() !== "") {
                    $(elem).find('label').addClass('selected');
                }

			}).on('focus', function(){
				$(elem).addClass('selected');
                $(elem).find('label').addClass('selected');
			});
		});

    });

    $(document).bind('ready, input', function(){
        $('.woocommerce-form-row, .form-row').each(function(i, elem) {
            if($(elem).find('input').val() !== "") {
                $(this).find('label').addClass('selected');
            }
			$(elem).find('input:not([type=radio]):not([type=checkbox])').on('blur', function(){
	        	$(elem).removeClass('selected');
                $(elem).find('label').removeClass('selected');
                if($(this).val() !== "") {
                    $(elem).find('label').addClass('selected');
                }

      		}).on('focus', function(){
        		$(elem).addClass('selected');
                $(elem).find('label').addClass('selected');
                $(this).addClass('selected');
        	});

			$(elem).find('textarea').on('blur', function(){
				$(elem).removeClass('selected');
                $(elem).find('label').removeClass('selected');
                if($(this).val() !== "") {
                    $(elem).find('label').addClass('selected');
                }

			}).on('focus', function(){
				$(elem).addClass('selected');
                $(elem).find('label').addClass('selected');
			});
		});
    });

    $('.woocommerce-form-row, .form-row').each(function(i, elem) {
        if($(elem).find('input').val() !== "") {
            $(this).find('label').addClass('selected');
        }
        $(elem).find('input:not([type=radio]):not([type=checkbox])').on('blur', function(){
            $(elem).removeClass('selected');
            $(elem).find('label').removeClass('selected');
            if($(this).val() !== "") {
                $(elem).find('label').addClass('selected');
            }

        }).on('focus', function(){
            $(elem).addClass('selected');
            $(elem).find('label').addClass('selected');
            $(this).addClass('selected');
        });

        $(elem).find('textarea').on('blur', function(){
            $(elem).removeClass('selected');
            $(elem).find('label').removeClass('selected');
            if($(this).val() !== "") {
                $(elem).find('label').addClass('selected');
            }

        }).on('focus', function(){
            $(elem).addClass('selected');
            $(elem).find('label').addClass('selected');
        });
    });

    $(document).bind('input', function(){
        $('.woocommerce-form-login input[type=password]').siblings('label').addClass('selected');
        $('.woocommerce-form-row input[type=password]').siblings('label').addClass('selected');
    });

}(jQuery));
