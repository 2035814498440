(function($) {
    appear({
      init: function init(){
        // console.log('dom is ready');
      },
      elements: function elements(){
        // work with all elements with the class "Overview-block-desktop"
        return document.getElementsByClassName('Overview-block-desktop');
      },
      appear: function appear(el){
        el.querySelector('video').play();
      },
      disappear: function disappear(el){
        el.querySelector('video').pause();
      },
      // bounds: 200,
      reappear: true
    });
}(jQuery));