/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("GetFinance", ["jquery", "Behaviors", "jquery.slick", "get-finance"], factory);
    } else {
        root.GetFinance = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors, window, document, undefined) {
    "use strict";

    		$(document).gfSimulator({
    		    // css selector for the html elements you wish to add the estimator to.
    		    priceSelector: ".quoteAmountTarget",

    		    // containerSelector: ".estimator", // (OPTIONAL) css selector for the html element where you want to place the estimator.
    		                                        // if not specified it adds it next to the price
    		    token: "N2RjNTU5YmNhNWRjMDhkZjI4YWEwOWM1YTQyNjE3OTY=", // This is your GetFinancing access token,
    		                                                   // please don't modify this part.
    		    customText: "or just $/month", // (OPTIONAL) Customize the text that appears under the product's price, the $ symbol will
    		                                // automatically be replaced by the monthly price
    		    minAmount: 100, // (OPTIONAL) Choose a minimum amount for the MPE to appear.
    		                    // Note that the minimum amount for the MPE to appear under a product's price also depends on the lenders' policy.
    		    branded: false, // (OPTIONAL) If set to true, GetFinancing™'s logo will appear right next to the MPE text
    		    debug: false, // (OPTIONAL) debug mode: set this to true, to see debugging messages on the browser's console

    		    // options to submit an application directly from the widget
    		    // applyNow: false, // If set to true, a link to the simple loan form will be added
    		    // productNameSelector: undefined, // if applyNow is set to true, this selector is REQUIRED
    		});
    		// (OPTIONAL) Dynamic update. Whenever your prices change, to update the estimator, call this function:
    		// jQuery(document).gfSimulator("update")
    		//  or if you only want to update a specific price estimator:
    		// jQuery("the-estimator-to-update").gfSimulator("update");


}));