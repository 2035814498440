/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ProductGallerySlider", ["jquery", "Behaviors", "jquery.slick"], factory);
    } else {
        root.ProductGallerySlider = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    function ProductGallerySlider(elem) {
        Behaviors.init(ProductGallerySlider, this, arguments);

        var slide_count = this.$elem.children().length;
        if(slide_count > 1) {
            this.$elem.slick({
                infinite: true,
                arrows: true,
                slidesToShow: 1,
            });
        }
    }

    Behaviors.inherit(ProductGallerySlider, Behaviors.Behavior);
    ProductGallerySlider.QUERY = ".woocommerce-product-gallery__wrapper";

    Behaviors.register_behavior(ProductGallerySlider);

    module.ProductGallerySlider = ProductGallerySlider;

    return module;
}));
