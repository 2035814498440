(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("shiftnav", ["jquery", "betteroffcanvas"], factory);
    } else {
        // Browser globals
        root.siteheader = factory(root.jQuery, root.betteroffcanvas);
    }
}(this, function ($, betteroffcanvas) {
    "use strict";
    "feel good";

    $("#primary_navigation2, #primary_navigation3, #SiteHeader-offcanvas_call").on('offcanvas-open', function() {
        $("#SiteHeader-main").addClass('SiteHeader-zindex-active');
    });

    $("#primary_navigation2, #primary_navigation3, #SiteHeader-offcanvas_call").on('offcanvas-dismiss', function() {
        $("#SiteHeader-main").removeClass('SiteHeader-zindex-active');
    });
    
    $('#SiteHeader-contact_drawer').on('offcanvas-open', function() {
        $('.SiteHeader--fixed').addClass('SiteHeader-navigation_drawer--open');
        $('body').addClass('SiteHeader-navigation_drawer_gap');
        $('.SiteHeader-container').addClass('SiteHeader-container--visible');
    });

    $('#SiteHeader-contact_drawer').on('offcanvas-dismiss', function() {
        $('.SiteHeader--fixed').removeClass('SiteHeader-navigation_drawer--open');
        $('body').removeClass('SiteHeader-navigation_drawer_gap');
        $('.SiteHeader-container').removeClass('SiteHeader-container--visible');
    });

    $('#CartDrawer').on('offcanvas-open', function() {
       $('body').addClass('SiteHeader-cart_drawer--scroll_lock');
       $('.SiteHeader-container').addClass('SiteHeader-container--visible'); 
    });

    $('#CartDrawer').on('offcanvas-dismiss', function() {
       $('body').removeClass('SiteHeader-cart_drawer--scroll_lock'); 
       $('.SiteHeader-container').removeClass('SiteHeader-container--visible');
    });

    
    $('#SiteHeader-offcanvas_call').on('offcanvas-open', function() {
         $('.SiteHeader-container').addClass('SiteHeader-container--visible');  
    });

    $('#CartDrawer').on('offcanvas-dismiss', function() {
        $('.SiteHeader-container').removeClass('SiteHeader-container--visible');  
    });

}));
