(function($) {
    jQuery( document.body ).on( 'checkout_error', function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    } );

    if($('.woocommerce-checkout').length || $('.woocommerce-account').length) {
        $("#billing_state, #shipping_state").attr("data-placeholder", "State");
        $("#billing_state, #shipping_state").select2();
    }
}(jQuery));
