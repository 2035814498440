/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ProductOverviewSlider", ["jquery", "Behaviors", "jquery.slick"], factory);
    } else {
        root.ProductOverviewSlider = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    function ProductOverviewSlider(elem) {
        Behaviors.init(ProductOverviewSlider, this, arguments);


        var slide_id = this.$elem.attr("id");

        var slide_count = this.$elem.children().length;
        if(slide_count > 1) {
            this.$elem.slick({
                arrows: false,
                asNavFor: ProductOverviewSliderDots.QUERY,
                fade: true,
                centerMode: true,
                lazyLoad: 'ondemand',
                swipe: false,
            });
        }
    }

    Behaviors.inherit(ProductOverviewSlider, Behaviors.Behavior);
    ProductOverviewSlider.QUERY = "[data-overviewblock-slider]";

    function ProductOverviewSliderDots(elem) {
        Behaviors.init(ProductOverviewSliderDots, this, arguments);

        var dotsControlNavItem = this.$elem.attr("data-control-slider");

        var dot_count = this.$elem.children().length;
        if(dot_count > 1) {
            this.$elem.slick({
                arrows: false,
                slidesToShow: dot_count,
                asNavFor: $(dotsControlNavItem),
                focusOnSelect: true,
                swipe: false,
            });
        }
    }

    Behaviors.inherit(ProductOverviewSliderDots, Behaviors.Behavior);
    ProductOverviewSliderDots.QUERY = "[data-overviewblock-slickdots]";


    Behaviors.register_behavior(ProductOverviewSlider);
    Behaviors.register_behavior(ProductOverviewSliderDots);

    module.ProductOverviewSlider = ProductOverviewSlider;
    module.ProductOverviewSliderDots = ProductOverviewSliderDots;

    return module;
}));
