/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ProductInteractions", ["jquery", "Behaviors", "jquery.slick", "GetFinance"], factory);
    } else {
        root.ProductInteractions = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors, window, document, undefined) {
    "use strict";

		function update_price(composite, component) {
			//component dom element
			var component_element = $('[data-item_id="' + component.component_id + '"]');

			//GET PRICE OF SELECTED OPTION
			var selected_price = composite.api.get_component_totals(component.component_id).price;

			//GET ALL OPTIONS IN COMPONENT
			var options_data = component.find_options_data();

			//ITERATE OVER OPTIONS
			for ( var i = 0; i < options_data.length; i++ ) {
				//option element
				var option_element = component_element.find('[data-val="' + options_data[i].option_id + '"]');

				//option price
				var option_price = options_data[i].component_price;

				//price-wrapping dom element
				var option_price_wrap_element = option_element.find('.woocommerce-Price-amount');

				//option price wrapper element
				var option_price_element = option_element.find('.woocommerce-Price-value');

				//compare option price to selected price
				var price_difference = Number(option_price) - selected_price;

				//update wrapper with class based on price difference
				var sign = "";

				if (price_difference < 0) {
					sign = "-";
					option_price_wrap_element.removeClass("sign-none sign-positive");
					option_price_wrap_element.addClass("sign-negative");
				}
				else if (price_difference > 0) {
					sign = "+";
					option_price_wrap_element.removeClass("sign-none sign-negative");
					option_price_wrap_element.addClass("sign-positive");
				} else {
					option_price_wrap_element.removeClass("sign-positive sign-negative");
					option_price_wrap_element.addClass("sign-none");
				}

				//update price element to be relative to selected price
				option_price_element.text( Math.abs((Number(option_price) - selected_price).toFixed(2)) );
			}
		}

		function group_toggle(group) {
			var toggle = $('[data-group-toggle="' + group + '"]');

			//make other toggles inactive
			$("[data-group-toggle]").parent().removeClass("is-GroupedContent--target_active");
			$("[data-group-toggle]").parent().addClass("is-GroupedContent--target_inactive");

			//make clicked toggle active
			$(toggle).parent().removeClass("is-GroupedContent--target_inactive");
			$(toggle).parent().addClass("is-GroupedContent--target_active");

			if (group === "all") {
				$("[data-group]").addClass("is-GroupedContent--active");
				$("[data-group]").removeClass("is-GroupedContent--inactive");
			} else {
				//enable elements for this group
				$("[data-group='" + group + "']").each(function() {
					$(this).removeClass("is-GroupedContent--inactive");
					$(this).addClass("is-GroupedContent--active");
				});

				//disable other elements
				$("[data-group][data-group!='" + group + "']").each(function() {
					$(this).addClass("is-GroupedContent--inactive");
					$(this).removeClass("is-GroupedContent--active");
				});
			}
		}

		$( '.composite_data' )

			.on( 'wc-composite-initializing', function( event, composite ) {

				// When the totals for the composite change


				composite.actions.add_action('composite_totals_changed', function(){



				});
				

				composite.actions.add_action('component_selection_changed', function(component){

					// If the selected step is a case component
					// find the image assets and swap the featured attributes. 
					if (component.step_title == 'Case') {
						var imageSrc = component.get_selected_product_image_data().image_src;
						var imageSrcset = component.get_selected_product_image_data().image_srcset;
						var imageAlt = component.get_selected_product_image_data().image_title;

						$('.Customizer-image_ratio_box img').attr({
							src: imageSrc,
							srcset: '',
							alt: imageAlt
						});						
					}


					//UPDATE COMPONENT PRICES
					update_price(composite, component);

					// We need to update because Ajax will change the price constantly
					$(".Customizer-specs_wrapper .gfsimulator").gfSimulator("update");

					//UPDATE COLLAPSE TOGGLE WITH SELECTED OPTION TITLE
					$('[data-collapse-component-id="' + component.component_id + '"]').text(component.get_selected_product_title());

					//AUTO COLLAPSE SELECTED COMPONENT
					var component_element = $('#component_'+component.component_id);
					CollapseContent.CollapseContentRegion.locate(component_element).make_hidden();
				}, 100);

				composite.actions.add_action('component_options_loaded', function(component){


					//UPDATE COMPONENT PRICES
					update_price(composite, component);

					//UPDATE COLLAPSE TOGGLE WITH SELECTED OPTION TITLE
					if (component.get_selected_product_title() !== "None") {
						$('[data-collapse-component-id="' + component.component_id + '"]').text(component.get_selected_product_title());
					}
				}, 100);

				composite.actions.add_action('options_state_rendered', function(component){

					//UPDATE COMPONENT PRICES
					update_price(composite, component);

					//UPDATE BEHAVIORS SO INFO MODALS WORK AFTER AJAX FILTERING OPTIONS
					Behaviors.content_ready( $('#component_' + component.component_id) );
					
					//UPDATE COLLAPSE TOGGLE WITH SELECTED OPTION TITLE
					if (component.get_selected_product_title() !== "None") {
						$('[data-collapse-component-id="' + component.component_id + '"]').text(component.get_selected_product_title());
					}

					//MANUALLY OPEN MODALS / STOP PROP FROM SELECTING OPTION
					$(".Component-option_info_modal_toggle").on("click", function(e) {
						betteroffcanvas.initOffcanvas( $($(this).data('target')), "nohover" );
						betteroffcanvas.openOffcanvas( $($(this).data('target')) );
						e.stopPropagation();
					});

				}, 10);


				//SCROLL TO COMPONENT WHEN ELEMENT IS CLICKED IN PRODUCT SPEC AREA
				$('.summary_element').on("click", function() {
					var comp_id = $(this).data('item_id');
					var component = $('#component_' + comp_id);
					var group = component.data('group');
					var step = composite.get_step( comp_id );

					//go to component's tab
					group_toggle( group );

					//open component collapse
					CollapseContent.CollapseContentRegion.locate(component).make_visible();

					//scroll to component
					composite.navigate_to_step( step );
				});
			} );

		//"TAB" FUNCTIONALITY FOR COMPONENTS
		$("[data-group-toggle]").on("click", function() {
			var group = $(this).data('group-toggle');

			//open tab
			group_toggle( group );
		});


		// Custom interaction to toggle other site elements
		// when the customizer specs is opened.
		$('#Customizer-specs').on('collapsecontent-toggle', function(event) {
	       
	       // If the object is reported as visible
	       if (event.originalEvent.visible == true) {
				$('.Customizer-specs_background').addClass('Customizer-open_animation');
				$('.Customizer-specs_wrapper').addClass('Customizer-open_wrapper');
			} else {
				$('.Customizer-specs_background').removeClass('Customizer-open_animation');
				$('.Customizer-specs_wrapper').removeClass('Customizer-open_wrapper');
			}
		});

		//Toggles all components if they need to be. 
		$("[data-show-all]").on("click", function(){

			// Add class to button once activated
			var buttonToggled = $(".GroupedContent-collapse_all--trigger").hasClass("is-ContentVisible");

			// For every component tab we will loop through each one
			$(".Component.single").each(function() {	
				
				// Return collapse content state
				// true if toggled || false is closed	
				var isVisible = CollapseContent.CollapseContentRegion.locate($(this)).visible;

				// If the button was not clicked and thinfs are not visible
				// We manually fire the toggle for said elements
				if (!buttonToggled && isVisible == false) {
					CollapseContent.CollapseContentRegion.locate($(this)).make_visible();
					$(".GroupedContent-collapse_all--trigger").text("Close All Options");
				}
				// If our button was clicked then hide all elements
				if ( buttonToggled ) {
					CollapseContent.CollapseContentRegion.locate($(this)).make_hidden();
					$(".GroupedContent-collapse_all--trigger").text("Show All Options");
				}

			});

			// No matter what we need the class to toggle on first click
			$(".GroupedContent-collapse_all--trigger").toggleClass("is-ContentVisible");

		});
}));





