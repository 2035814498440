/*global define, console*/

(function (root, factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        define("ImageSpin", ["jquery", "Behaviors"], factory);
    } else {
        root.ImageSpin = factory(root.jQuery, root.Behaviors);
    }
}(this, function ($, Behaviors) {
    "use strict";

    var module = {};

    function ImageSpin(elem) {
        Behaviors.init(ImageSpin, this, arguments);

        //this.$elem.parents('.PricingTable-column').bind("webkitAnimationEnd mozAnimationEnd animationEnd", this.remove_class.bind(this));
        this.$elem.parents('.PricingTable-column').bind("mouseenter", this.add_class.bind(this));
        this.$elem.parents('.PricingTable-column').bind("mouseleave", this.timeout.bind(this));
    }

    Behaviors.inherit(ImageSpin, Behaviors.Behavior);
    ImageSpin.QUERY = "[data-image-spin]";

    ImageSpin.prototype.add_class = function(evt) {
        this.$elem.addClass('spin');
    }

    ImageSpin.prototype.timeout = function(evt) {
        setTimeout(function() {
            this.$elem.removeClass("spin");
        }.bind(this), 300);
    }

    Behaviors.register_behavior(ImageSpin);

    module.ImageSpin = ImageSpin;

    return module;
}));
